<template>

    <v-card class="pa-5 pb-1 ma-2 thumbnail-card" style="position: relative;">

      <a :href="path" target="_blank">
        <template v-if="isPDF">
          <div style="height: 120px; width: 120px;" class="d-flex align-center justify-center" :title="display">
            <i class="fas fa-file-pdf" style="font-size: 72px; color: #AD0B00;"></i>
          </div>
        </template>
        <template v-else-if="isWord">
          <div style="height: 120px; width: 120px;" class="d-flex align-center justify-center" :title="display">
            <i class="fas fa-file-word" style="font-size: 72px; color: rgb(68, 136, 227);"></i>
          </div>
        </template>
        <template v-else-if="isCSV">
          <div style="height: 120px; width: 120px;" class="d-flex align-center justify-center" :title="display">
            <i class="fas fa-file-csv" style="font-size: 72px; color: #333333;"></i>
          </div>
        </template>
        <template v-else>
          <v-img
            height="120"
            width="120"
            :src="path"
            :title="display"
          ></v-img>
        </template>

        <span class="file-name" :title="display">{{display}}</span>
      </a>
      

      <v-btn color="#9F2242" type="button" icon x-small @click="removeFile()" v-if="!disabled" style="position: absolute; top: 2px; right: 2px;">
          <v-icon>fa-times</v-icon>
      </v-btn>

    </v-card>

</template>

<script>
export default {
  name: "ImageThumbnail",
  data() {
    return {

    }
  },
  props: {
    path: String,
    display: String,
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'remove'
  ],
  computed: {
    isPDF() {
      return this.path.indexOf('.pdf') > -1;
    },
    isWord() {
      return this.path.indexOf('.doc') > -1;
    },
    isCSV() {
      return this.path.indexOf('.csv') > -1;
    },
  },
  methods: {
    removeFile() {
      this.$emit('remove');
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

.thumbnail-card {
  display: inline-block;
  width: auto;
}

.file-name {
    color: $gray1;
    display: block;
    text-overflow: ellipsis;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
}

a {
  text-decoration: none;
  display: block;
  

  &:hover {
    opacity: 0.9;
  }
}


</style>