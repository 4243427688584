<template>
    <div>

            <v-row class="pt-0 pb-2">
                <v-col cols="12" class="d-flex align-center py-0">
                    <label class="main-label mb-0">
                        {{required ? label + '*' : label}}&nbsp;
                    </label>
                    <v-spacer></v-spacer>
                    <v-btn v-if="template" color="white" type="button" target="_blank"
                        :href="documentTemplate(template)">
                            <i class="far fa-file-edit"></i>&nbsp;
                            Download Template
                    </v-btn>
                </v-col>
            </v-row>

            <p v-if="description" v-html="description" class="description mb-0"></p>
            

            <input type="hidden" name="fileList" :value="fileList" />

            <div class="text-center mt-4" id="upload" :class="{'hovered': hovered, 'hasError': errors && errors.length}" v-show="!disabled && newValue.length < max">
                <div class="upload-inner" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input type="file" 
                        multiple 
                        name="fields[assetsFieldHandle][]" 
                        id="assetsFieldHandle" 
                        @change="onChange" 
                        ref="file" 
                        :accept="accept" />
                
                    <label for="assetsFieldHandle">
                        <div class="cursor-pointer">
                            <i class="fas fa-cloud-upload-alt fa-lg"></i><br>
                            <p style="margin-top: 10px; margin-bottom: 0;" v-if="max == 1">Drag and drop your file here or click to upload.</p>
                            <p style="margin-top: 10px; margin-bottom: 0;" v-else>Drag and drop files here or click to upload. (Max {{max}})</p>
                        </div>
                    </label>
                </div>
            </div>

            <v-alert type="error" color="#9F2242" dense dismissible v-model="showUnsupportedFileType" class="mt-2">
                Unsupported file type: {{unsupportedFileNames.join(', ')}}
            </v-alert>

            <v-alert type="info" dense v-if="newValue.length >= max && max > 1" class="mt-2">
                File count limit reached.
            </v-alert>

            <v-alert type="info" dense dismissible v-model="showMaxMbExceeded" class="mt-2">
                File would exceed upload size limits.
            </v-alert>

            <div v-if="newValue.length" v-cloak style="margin-top: 10px; text-align: center;">
                <ImageThumbnail 
                    v-for="(file, index) in newValue" :key="file.FileName"
                    :path="filePath(file)" 
                    :display="file.DisplayName" 
                    :disabled="disabled" 
                    @remove="removeFile(index)"
                />
            </div>
            
            <ErrorMessages :errors="errors"></ErrorMessages>

        
    </div>
</template>

<script>
import ImageThumbnail from '@/components/shared/ImageThumbnail.vue'
import Environment from '@/scripts/environment'
import { resizeImage, sanitizeFileName } from '@/scripts/helper'
import { errorHandler } from '@/plugins/axios'
import { getTokenAsync } from '@/plugins/auth'

export default {
    name: 'FileUpload',
    components: {
        ImageThumbnail
    },
    props: {
        value: Array,
        rules: String,
        label: String,
        required: Boolean,
        description: String,
        errors: Array,
        disabled: Boolean,
        template: {
            type: String,
            default: null
        },
        product: {
            type: String,
            default: null
        },
        accept: {
            type: String,
            default: "image/*, .pdf, .doc, .docx"
        },
        max: {
            type: Number,
            default: 10
        },
        maxMb: {
            type: Number,
            default: 50
        },
        shouldCreateTempFileId: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'change',
        'validate'
    ],
    data() {
        return {
            fileList: [],
            hovered: false,
            newValue: [],
            showUnsupportedFileType: false,
            showMaxMbExceeded: false,
            unsupportedFileNames: [],
            host: Environment.API_HOST
        }   
    },
    methods: {
        uploadedFilesSize() {
            let size = 0;
            this.newValue.forEach(f => {
                size += f.FileSize || 0;
            });

            return size / 1000000;
        },
        async onChange() {
            this.showUnsupportedFileType = false;
            this.showMaxMbExceeded = false;
            this.unsupportedFileNames = [];

            const newFiles = this.$refs.file.files;

            for (let n of newFiles) {
                n = await resizeImage(n);
                const potentialSize = this.uploadedFilesSize() + (n.size / 1000000);
                
                if ( potentialSize > this.maxMb) {
                    this.showMaxMbExceeded = true;
                } else if (this.isAcceptedFileType(n.name)) {
                    await this.uploadFile(n);
                } else {
                    this.showUnsupportedFileType = true;
                    this.unsupportedFileNames.push(n.name);
                }
            }
            

            this.$refs.file.type = 'text';
            this.$refs.file.type = 'file';


        },
        documentTemplate(template) {
            if (!template) {
                return null
            } else if (template.indexOf('http://') > -1 || template.indexOf('https://') > -1) {
                return template;
            } else {
                return this.host + '/documents/' + template;
            }
        },
        getExtension(filename) {
            if (!filename) return '';
            var parts = filename.split('.');
            return parts[parts.length - 1];
        },
        isAcceptedFileType(filename) {
            const e = this.getExtension(filename);
            const acceptedExtensions = [
                'pdf',
                'doc',
                'docx',
                'jpeg',
                'jpg',
                'png',
                'bmp',
                'csv'
            ];

            return acceptedExtensions.indexOf(e) > -1;
        },
        async uploadFile(file) {
            if (this.newValue.length >= this.max) {
                return;
            }

            const extension = this.getExtension(file.name);
            let contentType = 'application/json'

            switch (extension) {
                case 'pdf':
                    contentType = 'application/pdf'
                    break;
                case 'doc':
                    contentType = 'application/msword'
                    break;
                case 'docx':
                    contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    break;
                case 'jpeg':
                case 'jpg':
                    contentType = 'image/jpeg'
                    break;
                case 'png':
                    contentType = 'image/png'
                    break;
                case 'bmp':
                    contentType = 'image/bmp'
                    break;
                case 'csv':
                    contentType = 'text/csv'
                    break;
                default:
                    break;
            }

            const requestURL = '/api/TempFile?displayName=' + encodeURI(sanitizeFileName(file.name)) + '&createTempFileId=' + this.shouldCreateTempFileId;
            const options = { 
                headers: {
                    "Content-Type": contentType,
                    "Authorization": "Bearer " + await getTokenAsync(),
                },
                handleError: false
            }

            try {
                const response = await this.$http.post(requestURL, file, options)
                this.newValue.push(response.data);
                this.emitValue();
            } catch (err) {
                if ( err.response && err.response.status == 403) {
                    this.$store.dispatch('toastError', {Message: 'Corrupted File. Please resave the file and try again.'});
                } else {
                    errorHandler(err);
                }
            }
           
        },
        filePath(obj) {
            const baseURL = this.$http.defaults.baseURL;
            return baseURL + '/api/TempFile/' + obj.FileName;
        },
        removeFile(i) {
            this.newValue.splice(i, 1);                  
            this.emitValue();  
        },
        emitValue() {
            this.$emit('change', this.newValue);

            setTimeout(() => {
                this.$emit('validate');
            }, 100)  
                     
        },
        dragover(event) {
            event.preventDefault();
            this.hovered = true;
        },
        dragleave(event) {
            event.preventDefault();
            
            if (this.errors) {
                this.$emit('validate');   
            }
            
            this.hovered = false;
        },
        drop(event) {
            event.preventDefault();
            if (this.disabled) return;

            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); 
            this.hovered = false;
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.newValue = newVal;
                } else if (newVal === null) {
                    this.newValue = [];
                }
            }
        },
    },
}

</script>

<style scoped lang="scss">
    @import '@/style.scss';

    [v-cloak] {
        display: none;
    }

    #upload {
        border: 2px solid $amwins-blue;
        background-color: #F7FAFF;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        height: 100px;
        color: $amwins-blue;
    }

    #upload.hovered,
    #upload:hover {
        opacity: 0.8;
    }

    #upload.hasError {
        border-color: $tertiary-red;
    }

    .upload-inner {
        position: relative;
        cursor: pointer;
        padding-top: 25px;
    }

    #upload input[type=file] {
        opacity: 0; 
        position: absolute; 
        top: 0; 
        left: 0; 
        min-width: 100%; 
        height: 120px;
        cursor: pointer;
        padding: 30px;
    }

    .cursor-pointer * {
        cursor: pointer;
    }

    .file-name {
        color: $amwins-blue;
        text-decoration: none;
        cursor: pointer;
    }

    .remove-file {
        cursor: pointer;
        color: $amwins-blue;
    }

    .main-label {
        display: inline-block;
        font-weight: bold;
        line-height: 36px;
    }

    .main-label, .description {
        display: block;
        color: rgba(0, 0, 0, 0.87);
    }

</style>
